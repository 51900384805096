<template>
    <div class="mtg-dashboard-view">
        <div class="ctrl-bar">
            <div class="label">日期：</div>
            <f-date-picker
                class="input"
                style="width: 240px"
                v-model:date="dateRes"
                :is-date-disabled="disableDate"
                @update:date="onQueryChange"
            ></f-date-picker>
            <div class="label">粒度：</div>
            <n-radio-group
                v-model:value="granularity"
                name="granularity"
                size="small"
                class="input"
                @update:value="onQueryChange"
            >
                <n-radio-button label="日" value="day"></n-radio-button>
                <n-radio-button label="周" value="week"></n-radio-button>
                <n-radio-button label="相对周" value="7"></n-radio-button>
                <n-radio-button label="月" value="month"></n-radio-button>
            </n-radio-group>
        </div>
        <div class="content">
            <TrendChart
                v-for="(item, index) in chartList"
                class="item"
                :loading="loading"
                :data="data[item.dataKey]"
                :trend="data[item.trendKey]"
                :percentage="!!item.percentage"
                :name="item.name"
                :index="index"
                :react-drag="dragMode"
                @drag-chart="onDrag(index)"
                @drag-chart-end="onDragEnd"
                @react-drag-enter="onReactDragEnter(index)"
                @react-drag-leave="onReactDragLeave(index)"
            ></TrendChart>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.mtg-dashboard-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .content {
        margin-top: 10px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: 48%;
            margin-bottom: 10px;
            flex-grow: 1;

            &:nth-child(odd) {
                margin-right: 5px;
            }

            &:nth-child(2n) {
                margin-left: 5px;
            }
        }
    }
}
</style>

<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';

import { NRadioButton, NRadioGroup } from 'naive-ui';

import FDatePicker from '@/components/FinOpsDatePicker';
import TrendChart from './components/TrendChart';
import { useDateRange } from '@/common/QueryDateRange';

import { getDashboard } from '@/common/API';

import DragState from '@/common/DragState';
import chartListConf from './charts.json';

const route = useRoute();
const router = useRouter();
const dateRange = useDateRange(route);

let chartList = ref(chartListConf);

let dragMode = ref(false);

function onDrag(index) {
    dragMode.value = true;
    DragState.setDragState(index);
}

function onDragEnd() {
    dragMode.value = false;
    let dragIndex = DragState.getDragState();
    let reactIndex = DragState.getReactState();
    if (reactIndex === null || reactIndex === dragIndex) {
        return;
    }
    let dragChart = chartList.value[dragIndex];
    chartList.value[dragIndex] = chartList.value[reactIndex];
    chartList.value[reactIndex] = dragChart;
    DragState.apply();
}

function onReactDragEnter(index) {
    DragState.setReactState(index);
}

function onReactDragLeave(index) {
    if (index === DragState.getReactState()) {
        DragState.setReactState(null);
    }
}

let dateRes = ref({ type: 'last7' });
let granularity = ref('day');

function initDateRes() {
    if (route.query.start && route.query.end) {
        dateRes.value = { range: [+route.query.start, +route.query.end] };
    } else if (route.query.qdt) {
        dateRes.value = { type: route.query.qdt };
    }
    if (route.query.granularity) {
        granularity.value = route.query.granularity;
    }
}
initDateRes();

function disableDate(value) {
    return dayjs().subtract(2, 'day').valueOf() < value;
}

function onQueryChange() {
    let dateQuery = {};
    // 写route的规则：如果选择了相对时间区间，则只写入qbt值
    if (dateRes.value.type) {
        dateQuery.qdt = dateRes.value.type;
    } else {
        dateQuery.start = dateRes.value.range[0];
        dateQuery.end = dateRes.value.range[1];
    }
    router.replace({ query: { ...dateQuery, granularity: granularity.value } });
}

let data = ref({});

let loading = ref(false);

function loadData() {
    data.value = {};
    loading.value = true;
    getDashboard(
        dateRange.value.start_time,
        dateRange.value.end_time,
        route.query.granularity || 'day'
    ).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            return;
        }
        data.value = res.data;
    });
}
loadData();

watch(dateRange, () => {
    if (route.name === 'mtg_dashboard') {
        loadData();
    }
});
</script>
