<template>
    <div
        class="trend-chart-view"
        draggable="true"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
    >
        <ChartBox
            :name="props.name"
            :loading="props.loading"
            @mounted="renderChart"
            @resize="onViewResize"
        >
            <template #footer>
                <div class="y-axis-ctrl-slider">
                    <n-slider
                        v-if="showYAxisSettingSlider"
                        v-model:value="yAxisValueRange"
                        :max="yAxisMax + yAxisPadding * 8"
                        :min="yAxisMin - yAxisPadding * 8"
                        style="height: 250px"
                        :tooltip="false"
                        :step="step"
                        vertical
                        range
                        @update:value="onYAxisValueRangeUpdate"
                    >
                        <template #thumb>
                            <div class="thumb"></div>
                        </template>
                    </n-slider>
                    <n-button
                        size="tiny"
                        :class="{
                            'toggle-btn': true,
                            active: showYAxisSettingSlider,
                        }"
                        type="text"
                        @click="
                            showYAxisSettingSlider = !showYAxisSettingSlider
                        "
                    >
                        <template #icon>
                            <n-icon>
                                <TuneFilled />
                            </n-icon>
                        </template>
                    </n-button>
                </div>
            </template>
        </ChartBox>
        <div
            v-show="props.reactDrag"
            class="drag-react-view"
            :style="{
                backgroundColor: isEnter && !isDrag ? '#9f0' : '#fff',
            }"
            @dragenter="onDragEnter"
            @dragover="e => !isDrag && e.preventDefault()"
            @dragleave="onDragLeave"
        ></div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';
.trend-chart-view {
    position: relative;
    height: 330px;

    .standard-border;
    .standard-shadow;

    .y-axis-ctrl-slider {
        position: absolute;
        width: 40px;
        height: 280px;
        padding: 10px 2px 4px;
        bottom: 0;

        .toggle-btn {
            position: absolute;
            bottom: 2px;
            left: 2px;
            color: #999;

            &.active {
                color: #333;
            }
        }

        .thumb {
            width: 8px;
            height: 16px;
            background-color: #f9f9f9;
            border-radius: 2px;
            box-shadow: 0px 0px 4px @primary-color;
        }
    }

    .drag-react-view {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.2;
        left: 0;
        top: 0;
    }
}
</style>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';

import { NButton, NIcon, NSlider } from 'naive-ui';
import { TuneFilled } from '@vicons/material';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { yAxisLabelFormatter } from '@/common/EChartsTools';
import { formatNumber, formatAxisData } from '@/common/tools';

import ChartBox from '@/components/ChartBox';

const route = useRoute();

const props = defineProps({
    name: {
        type: String,
    },
    data: {
        type: Object,
        default() {
            return {};
        },
    },
    trend: {
        type: Object,
        default() {
            return {};
        },
    },
    loading: {
        type: Boolean,
        default: false,
    },
    percentage: {
        type: Boolean,
        default: false,
    },
    reactDrag: { type: Boolean, default: false },
});

const emits = defineEmits([
    'dragChart',
    'dragChartEnd',
    'reactDragEnter',
    'reactDragLeave',
]);

const xAxisData = computed(() => Object.keys(props.data));

const baseLineData = computed(() => Object.values(props.data));

const trendLineData = computed(() => Object.values(props.trend));

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    UniversalTransition,
]);

let echartInstance = null;

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

function setChartOption() {
    echartInstance.setOption({
        grid: {
            top: 10,
            left: 66,
            bottom: 30,
            right: 20,
        },
        tooltip: {
            trigger: 'axis',
            appendToBody: true,
            valueFormatter: value => {
                if (!value) {
                    return '-';
                }
                return props.percentage
                    ? `${(value * 100).toFixed(2)}%`
                    : formatNumber(value);
            },
        },
        xAxis: {
            type: 'category',
            data: formatAxisData(xAxisData.value, route.query.granularity),
        },
        yAxis: {
            type: 'value',
            ...yAxisMaxMinRange.value,
            ...yAxisLabelFormatter(props.percentage ? 'percent' : 'number'),
        },
        series: [
            {
                name: '趋势',
                data: baseLineData.value,
                type: 'line',
                label: {
                    show: true,
                    formatter: series => {
                        let remainder = xAxisData.value.length / 10 + 1;
                        if (series.dataIndex % Math.floor(remainder) !== 0) {
                            return '';
                        }
                        return props.percentage
                            ? `${(series.value * 100).toFixed(2)}%`
                            : formatNumber(series.value);
                    },
                },
                symbolSize: 2,
            },
            {
                name: '7日均线',
                data: trendLineData.value,
                type: 'line',
                showSymbol: false,
                smooth: true,
            },
        ],
    });
}

let showYAxisSettingSlider = ref(false);

let yAxisValueRange = ref([0, 10]);

let yAxisMax = ref(0);

let yAxisMin = ref(10);

let yAxisPadding = ref(0);

let step = ref(1);

const yAxisMaxMinRange = computed(() => {
    return { min: yAxisValueRange.value[0], max: yAxisValueRange.value[1] };
});

function onYAxisValueRangeUpdate(value) {
    setChartOption();
}

function getMinMax(arr) {
    let min = arr[0];
    let max = arr[0];
    for (let i = 1; i < arr.length; i++) {
        if (!arr[i]) {
            continue;
        }
        if (arr[i] < min) {
            min = arr[i];
        } else if (arr[i] > max) {
            max = arr[i];
        }
    }
    return { min, max };
}

watch(
    () => props.data,
    () => {
        if (baseLineData.value.length < 1) {
            echartInstance.clear();
            return;
        }
        let range = getMinMax([...baseLineData.value, ...trendLineData.value]);
        yAxisMax.value = range.max;
        yAxisMin.value = range.min;
        yAxisPadding.value = (range.max - range.min) / 10;
        yAxisValueRange.value = [
            range.min - yAxisPadding.value * 4,
            range.max + yAxisPadding.value * 4,
        ];
        step.value = (range.max - range.min) / 100;
        setChartOption();
    }
);

watch(
    () => props.reactDrag,
    value => {
        if (!value) {
            isEnter.value = false;
        }
    }
);

let isDrag = ref(false);

let isEnter = ref(false);

function onDragStart(e) {
    isDrag.value = true;
    e.dataTransfer.effectAllowed = 'move';
    emits('dragChart');
}

function onDragEnter(e) {
    isEnter.value = true;
    emits('reactDragEnter');
}

function onDragLeave(e) {
    isEnter.value = false;
    emits('reactDragLeave');
}

function onDragEnd(e) {
    isDrag.value = false;
    emits('dragChartEnd');
}
</script>
